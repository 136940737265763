/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { Route } from 'react-router-dom'

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About'))
const Home = lazy(() => import('../pages/Home/Home'))
const DialogDemo = lazy(() => import('../pages/DialogDemo/DialogDemo'))
const ToastDemo = lazy(() => import('../pages/ToastDemo/ToastDemo'))
const FilterDemo = lazy(() => import('../pages/FilterDemo'))
const ListPageDemo = lazy(() => import('../pages/ListPageDemo'))
const GameWorld = lazy(() => import('../pages/GameWorld'))
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))

const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  // <UnauthorizedRoute path="/signup" redirectTo="/" exact component={SignUp} />,
  // <UnauthorizedRoute
  //   path="/password_reset"
  //   redirectTo="/"
  //   exact
  //   component={PasswordReset}
  // />,
  <Route path="/about" exact component={About} />,
  <AuthorizedRoute path="/my_account" exact component={MyAccount} />,
  <AuthorizedRoute path="/home" exact component={Home} />,
  // <AuthorizedRoute path="/dialog_demo" exact component={DialogDemo} />,
  // <AuthorizedRoute path="/toast_demo" exact component={ToastDemo} />,
  // <AuthorizedRoute path="/filter_demo" exact component={FilterDemo} />,
  // <AuthorizedRoute path="/list_page_demo" exact component={ListPageDemo} />,
  <AuthorizedRoute path="/game" exact component={GameWorld} />,
]

export default routes
