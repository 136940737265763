import { createSlice } from '@reduxjs/toolkit'

import actionButtons from '../data/action_buttons.json'
import actionEvents from '../data/action_events.json'
import actionJobs from '../data/action_jobs.json'

var Chance = require('chance');

const randomChance = new Chance();
const FAILURE_TIME = 10;
const HUSK_JOB_INCR = 1
const HUSK_JOB_START = 0


export const playerReducer = createSlice({
  name: 'player',
  initialState: {
    dayDone: false,
    time: 0,
    day: 0,
    availableActions: actionButtons.filter(button => button["default"]),
    actions: [],
    logs: [],
    clicked: {},
    inventory: {"darkness": 1, "air": 1000000 },
    actionEventLog: [],
    availableJobs: [],
    showToast: false,
    husks: [],
  },
  reducers: {
    newGame: state => {
      state.dayDone =  false
      state.time =  0
      state.day =  0
      state.availableActions =  actionButtons.filter(button => button["default"])
      state.actions =  []
      state.logs = ["Game reset."]
      state.clicked = {}
      state.inventory = {"darkness": 1, "air": 1000000}
      state.actionEventLog = []
      state.availableJobs= []
      state.showToast = true
      state.husks = []
    },
    increment: state => {
      state.time += 1
    },
    closeToast: state => {
      state.showToast = false
    },
    assignHuskJob: (state, action) => {
      const { sid, job } = action.payload
      state.husks.find( (husk) => husk.sid === sid )["job"] = job
    },
    nextDay: state => {
      state.time = 0
      state.dayDone = false
      state.actions = []
      state.day += 1

      const clickedButtons = Object.keys(state.clicked)
      const { inventory } = state
      const husks = state.husks || []

      // Perform all jobs
      // console.log(JSON.stringify(state.availableJobs, undefined, 2))
      husks.filter((h) => h['job']).forEach((husk) => {
        // perform their job exchange
        const jobName = husk["job"]
        const jobDetail = state.availableJobs.find((job) => job.name === jobName)
        if (jobDetail) {
          const perform = canTake(jobDetail["cost"], state)
          if (perform.success) 
            exchange(jobDetail["cost"], jobDetail["give"], state)
            husk[jobName] = (husk[jobName] || HUSK_JOB_START) + HUSK_JOB_INCR
          }
      })

      // Add or Remove any husks
      const huskCount = inventory.husk
      if (huskCount > husks.length) {
        state.husks = husks.concat([newHusk(state)])
      } else if (huskCount < husks.length) {
        state.husks = husks.slice(1)
      }

      // Perform all events
      // events are done after husk updates to allow for 'move-in' time
      const selectedEvents = actionEvents.filter(evt => {
        const {name, predecessor, chance, cost} = evt
        const chances = chance.split('/')
        const target = chances[0]
        const range = chances[1]
        let success = predecessor ? clickedButtons.includes(predecessor) : true

        if(success){
          const roll = parseInt(Math.random() * range)
          console.log(`${name} ${roll} < ${target}`)
          return roll < target
        }
        
        return false
      })

      // Add events to the inventory
      if (selectedEvents) {
        selectedEvents.forEach((evt) => {
          const { label, log, name, give, cost, hideCost } = evt
          const requirements = itemsFromString(cost)
          const fullCost = requirements.map((item) => `${item[1]} ${item[0]}`).join()
          const canPerform = canTake(cost, state)
          let message = `Failed. More requested | ${canPerform.required} | Cost ${fullCost}`
          if (canPerform.success) {
            exchange(cost, give, state)
            const newItems = itemsFromString(give)
            const gainedItemString = newItems.map((item) => `${item[1]} ${item[0]}`).join()
            state.logs = state.logs.concat([log])
            message = `${label}! You gained | ${gainedItemString} | Cost ${fullCost}`
          }
          state.actionEventLog = state.actionEventLog.concat([{[label]: message}]) 
        })
      }

      // Set Available Buttons
      state.availableActions = actionButtons.filter(button => {
        let valid = !button["internal"]
        if (button.predecessor && !clickedButtons.includes(button.predecessor))
          valid = false
        if (button.blocker && clickedButtons.includes(button.blocker))
          valid = false
        return valid
      }).reverse()

      // Set Available Jobs
      state.availableJobs = actionJobs.filter(button => 
        button.blocker ? !clickedButtons.includes(button.blocker) :
        button.predecessor ? clickedButtons.includes(button.predecessor) : true
      )   
      state.showToast = true
    },
    performAction: (state, action) => {
      const { inventory } = state
      const { label, log, time, name, give, cost, hideCost } = action.payload

      const requirements = itemsFromString(cost)
      const fullCost = requirements.map((item) => `${item[1]} ${item[0]}`).join()

      const canPerform = canTake(cost, state)
      let message = `Failed. More requested | ${canPerform.required} | Cost ${fullCost}`
      if (canPerform.success) {
        exchange(cost, give, state)
        const newItems = itemsFromString(give)
        const gainedItemString = newItems.map((item) => `${item[1]} ${item[0]}`).join()

        message = `Success! You gained | ${gainedItemString} | ${!cost || hideCost ? '' : `Cost ${fullCost}`}`
        state.time += time
        state.logs = state.logs.concat([log])
        state.clicked[name] = state.clicked[name] ? state.clicked[name].concat([Date.now()]) : [Date.now()]
      } else {
        state.time += FAILURE_TIME
        state.logs = state.logs.concat([`${label} Failed! missing ${canPerform.required}`])
      }
      state.showToast = true
      state.actions = state.actions.concat([{[label]: message}]) 
      state.dayDone = state.time > 99
    }
  }
})

function displayItem(items) {
  return items.map((item) => `${item[1]} ${item[0]}`).join(', ')
}

function itemsFromString(sItems){
  if(!sItems)
    return []
  return sItems.split(",").map((i) => i.split(':')).map((a) => [a[0],Number(a[1])])
}

function randomName() {
  // Select a random spirit animal
  let animal = randomChance.animal().replace('-','').split(' ')
  animal = animal[animal.length - 1]
  // Select a random human spirit
  const human = randomChance.last()
  // Fallback name
  const fallback = randomChance.word({ syllables: 2 })
  return (animal.length < 10 && // last name less 10 characters 
          human.match(/^[a-z]+$/i) && // first name is readable
          (human.length + animal.length) < 21 ?  // Prevent trunct
            [human, fallback, animal] : 
            [fallback, human, animal]
        )
}

function newHusk(gameState){
  const name = randomName()
  const job = gameState.availableJobs && gameState.availableJobs.length > 0 ? 
              gameState.availableJobs[0]["name"] : ''
  const sid = [name[0][0],name[1][0],name[2][0]]
                .map((s) => s.toUpperCase()).join('') + '-' +
                Date.now().toString().match(/.{1,7}/g).join('-')
  return {
    "name": `${name[0]} ${name[2]}`,
    "birth": gameState.day,
    "sid": sid,
    "job": job
  }
}

function canTake(sCost, state){
  const { inventory } = state
  const cost = itemsFromString(sCost)
  
  const missingItems = cost.filter((item) => Number(inventory[item[0]] || 0) < item[1])
  const success = missingItems.length == 0
  const needed = missingItems.map((item) => `${Number(item[1]) - (Number(inventory[item[0]] || 0)).toFixed(2)} ${item[0]}`).join()
  
  return {
    success: success,
    required: !success ? needed : '',
  }
}

function exchange(sCost, sGive, state) {
  const cost = itemsFromString(sCost)
  const give = itemsFromString(sGive)

  const { inventory } = state
  cost.forEach((itemStack) => {
    const newAmount = (inventory[itemStack[0]] - itemStack[1])
    inventory[itemStack[0]] = Number((newAmount).toFixed(3))
  });
  give.forEach((itemStack) => {
    const currentAmount = inventory[itemStack[0]] 
    const newAmount = currentAmount ? currentAmount + itemStack[1] : itemStack[1]
    inventory[itemStack[0]] = Number((newAmount).toFixed(3))
  });
  return inventory
}

// function missingItems(itemStacks, inventory) {
//   return itemStacks.filter((item) => Number(inventory[item[0]] || 0) > item[1])
// }

// function canTakeItems(itemStacks, inventory) {
//   const missing = missingItems(itemStacks, inventory)
//   return missing.length == 0
// }

// function modifyItemStack(name, amount, inventory) {
//   inventory[name] = itemStack ? itemStack + amount : amount
//   return inventory
// }

// Action creators are generated for each case reducer function
export const { newGame, increment, nextDay, incrementByAmount, performAction, closeToast, assignHuskJob } = playerReducer.actions

export default playerReducer.reducer