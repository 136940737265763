import { configureStore } from '@reduxjs/toolkit'
import playerReducer from './playerReducer'
import { saveState, loadState } from '../utils/localStorage';
import throttle from 'lodash.throttle';


const store = configureStore({
  reducer: {
    player: playerReducer
  },
  preloadedState: loadState()
});

// We'll subscribe to state changes, saving the store's state to the browser's
// local storage. We'll throttle this to prevent excessive work.
store.subscribe(
	throttle( () => saveState(store.getState()), 1000)
);

export default store